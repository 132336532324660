import api from 'api'
import Pagination from 'components/Pagination/Pagination'
import { Button } from 'motorsport-ui-kit'
import React, { useState } from 'react'
import AddRulePopup from '../../components/AddRulePopup/AddRulePopup'
import CheckBox from '../../components/CheckBox/CheckBox'
import styles from './ExclusionRules.module.scss'
import spinner from '../../assets/img/spinner.svg'
import TableColTitle from '../../components/TableColTitle/TableColTitle'

type OrderBy = { value: keyof ExclusionRule; reverse: boolean }

const ExclusionRules = () => {
  const [params, setParams] = useState<ExclusionRuleParams>({ page: 0 })
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>({ value: 'id', reverse: true })
  const [isVisiblePopup, setIsVisiblePopup] = useState(false)
  const [selectedRule, setSelectedRule] = useState<ExclusionRule | undefined>()
  const { data, isFetching } = api.useGetExclusionRulesQuery({
    ...params,
    limit: 15,
    order_by: orderBy ? (orderBy.reverse ? '-' : '') + orderBy.value : undefined,
  })
  const [patchRuleRequest] = api.usePatchExclusionRuleMutation()
  const [deleteRuleRequest] = api.useDeleteExclusionRuleMutation()

  const onRuleClick = (rule: ExclusionRule) => {
    setSelectedRule(rule)
    setIsVisiblePopup(true)
  }

  const onAddRuleClick = () => {
    setSelectedRule(undefined)
    setIsVisiblePopup(true)
  }

  const onChangeOrderBy = (value: keyof ExclusionRule) => {
    setOrderBy((v) => {
      if (v?.reverse && v.value === value) return undefined
      else {
        const reverse = v?.value === value
        return {
          value,
          reverse,
        }
      }
    })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', zIndex: 0 }}>
      {isVisiblePopup && (
        <AddRulePopup onClose={() => setIsVisiblePopup(false)} rule={selectedRule} isNew={selectedRule === undefined} />
      )}
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>Exclusion rules</h1>
        <Button onClick={onAddRuleClick} className={styles.addRule}>
          Add a New Rule
        </Button>
      </div>
      <div className={styles.tableContainer}>
        {isFetching && (
          <div className={styles.tableLoader}>
            <img className={styles.tableLoaderSpinner} src={spinner} alt={'Loading...'} />
          </div>
        )}
        <table cellSpacing="0" cellPadding="0" className={styles.table}>
          <thead>
            <tr className={styles.tableTitleLine}>
              <th onClick={() => onChangeOrderBy('id')}>
                <TableColTitle name={'id'} label={'Id'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('type')} className={styles.tableType}>
                <TableColTitle name={'type'} label={'Type'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('new_used')} className={styles.tableNewUsed}>
                <TableColTitle name={'new_used'} label={'New/Used'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('make')} className={styles.tableMake}>
                <TableColTitle name={'make'} label={'Make'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('model')} className={styles.tableModel}>
                <TableColTitle name={'model'} label={'Model'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('condition')} className={styles.tableCondition}>
                <TableColTitle name={'condition'} label={'Condition'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('price_type')} className={styles.tablePriceType}>
                <TableColTitle name={'price_type'} label={'Price type'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('rule_status')} className={styles.tableRuleStatus}>
                <TableColTitle name={'rule_status'} label={'Rule status'} sorted={orderBy} />
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.objects.map((e) => (
              <tr className={styles.tableLine} key={e.id}>
                <td className={styles.tableId} onClick={() => onRuleClick(e)}>
                  {e.id}
                </td>
                <td className={styles.tableType}>{e.type}</td>
                <td className={styles.tableNewUsed}>{e.new_used}</td>
                <td className={styles.tableMake}>{e.make_name}</td>
                <td className={styles.tableModel}>{e.model_name}</td>
                <td className={styles.tableCondition}>{e.condition}</td>
                <td className={styles.tablePriceType}>{e.price_type_name}</td>
                <td className={styles.tableRuleStatus}>
                  <CheckBox
                    id={e.id}
                    onClick={(value) => {
                      patchRuleRequest({
                        id: e.id,
                        body: {
                          rule_status: value,
                        },
                      })
                    }}
                    selected={e.rule_status}
                  />
                </td>
                <td style={{ width: 50 }}>
                  <div onClick={() => deleteRuleRequest(e.id)} className={styles.delete} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data?.objects && data?.objects.length > 0 ? (
        <Pagination
          className={styles.pagination}
          pageCount={data?.pagination.count!}
          onChange={(p: number) => setParams({ ...params, page: p - 1 })}
          curPage={(params.page ?? 0) + 1}
        />
      ) : null}
    </div>
  )
}

export default ExclusionRules
