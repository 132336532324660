import React, { useState } from 'react'
import styles from './VinMaps.module.scss'
import api from '../../api'
import spinner from '../../assets/img/spinner.svg'
import TableColTitle from '../../components/TableColTitle/TableColTitle'
import Pagination from '../../components/Pagination/Pagination'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Link } from 'react-router-dom'
import Button from '../../components/Button/Button'
import Modal from '../../components/Modal/Modal'
import VinRemapForm from '../../components/VinRemapForm/VinRemapForm'
import deleteIcon from '../../assets/img/delete.svg'
dayjs.extend(localizedFormat)

type OrderBy = { value: keyof VinMap; reverse: boolean }

const VinMaps = () => {
  const [page, setPage] = useState(0)
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>({ value: 'update_at', reverse: true })
  const [selectedVinMap, setSelectedVinMap] = useState<VinMap | undefined>()
  const vinMaps = api.useGetVinMapsQuery(
    { page, order_by: orderBy ? (orderBy.reverse ? '-' : '') + orderBy.value : undefined },
    { refetchOnMountOrArgChange: true },
  )
  const [patchVinMap, patchVinMapResponse] = api.usePatchVinMapMutation()
  const [deleteVinMap, deleteVinMapResponse] = api.useDeleteVinMapMutation()

  const onChangeOrderBy = (value: keyof VinMap) => {
    setOrderBy((v) => {
      if (v?.reverse && v.value === value) return undefined
      else {
        const reverse = v?.value === value
        return {
          value,
          reverse,
        }
      }
    })
  }

  const onPatchVinMap = (id: number, body: Omit<VinMap, 'id'>) => {
    const resp = patchVinMap({ ...body, id })
    resp.finally(() => setSelectedVinMap(undefined))
  }

  const onDelete = (id: number) => {
    deleteVinMap(id)
  }

  return (
    <div>
      <div className={styles.header}>
        <Link to={'/exceptions'}>
          <Button className={styles.fixesBtn}>{'<'} Back</Button>
        </Link>
        <h1>Fixes</h1>
      </div>

      {selectedVinMap && (
        <Modal onCloseModal={() => setSelectedVinMap(undefined)}>
          <VinRemapForm
            key={selectedVinMap.id}
            title={'Edit Fix for Exception'}
            initialValues={{
              vin: selectedVinMap.vin,
              stock: selectedVinMap.stock,
              dealer_name: selectedVinMap.dealer_name,
              make_name: selectedVinMap.make_name,
              model_name: selectedVinMap.model_name,
            }}
            onSubmit={(body) => onPatchVinMap(selectedVinMap.id, body)}
            loading={patchVinMapResponse.isLoading}
            onClose={() => setSelectedVinMap(undefined)}
          />
        </Modal>
      )}

      <div className={styles.tableContainer}>
        {(vinMaps.isLoading || deleteVinMapResponse.isLoading) && (
          <div className={styles.tableLoader}>
            <img className={styles.tableLoaderSpinner} src={spinner} alt={'Loading...'} />
          </div>
        )}
        <table className={styles.table}>
          <colgroup>
            <col span={1} width={'1%'} />
            <col span={1} />
            <col span={1} />
            <col span={1} />
            <col span={1} />
            <col span={1} />
            <col span={1} width={'1%'} />
            <col span={1} width={'1%'} />
            <col span={1} width={'1%'} />
          </colgroup>
          <thead>
            <tr>
              <th onClick={() => onChangeOrderBy('id')}>
                <TableColTitle name={'id'} label={'Id'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('dealer')}>
                <TableColTitle name={'dealer'} label={'Dealer'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('make')}>
                <TableColTitle name={'make'} label={'Make'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('model')}>
                <TableColTitle name={'model'} label={'Model'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('stock')}>
                <TableColTitle name={'stock'} label={'Stock'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('vin')}>
                <TableColTitle name={'vin'} label={'Vin'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('update_at')}>
                <TableColTitle name={'update_at'} label={'Update at'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('create_at')}>
                <TableColTitle name={'create_at'} label={'Create at'} sorted={orderBy} />
              </th>
            </tr>
          </thead>
          <tbody>
            {vinMaps.data?.objects.map((m) => (
              <tr key={m.id}>
                <td onClick={() => setSelectedVinMap(m)} style={{ color: 'var(--color-blue)', cursor: 'pointer' }}>
                  {m.id}
                </td>
                <td>{m.dealer_name}</td>
                <td>{m.make_name}</td>
                <td>{m.model_name}</td>
                <td>{m.stock}</td>
                <td>{m.vin}</td>
                <td>{dayjs(m.update_at * 1000).format('lll')}</td>
                <td>{dayjs(m.create_at * 1000).format('lll')}</td>
                <td>
                  <button className={styles.deleteBtn} onClick={() => onDelete(m.id)} title={'Delete'}>
                    <img src={deleteIcon} alt={'Delete'} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {vinMaps.data && (
        <Pagination
          pageCount={vinMaps.data.pagination.count}
          curPage={page + 1}
          onChange={(p: number) => setPage(p - 1)}
          className={styles.pagination}
        />
      )}
    </div>
  )
}

export default VinMaps
