import React from 'react'
import styles from './Pagination.module.scss'
import classNames from 'classnames'

type Props = {
  pageCount: number
  curPage: number
  onChange?: (page: number) => void
  className?: string
}

const Pagination = ({ pageCount, onChange, className, curPage }: Props) => {
  if (pageCount < 2) return null
  const elements = []

  const onClick = (page: number) => {
    if (onChange) onChange(page)
  }

  for (let i = 1; i <= pageCount; i++) {
    const btn = (
      <button
        key={i}
        className={classNames(styles.pageBtn, { [styles.activePage]: curPage === i })}
        onClick={() => onClick(i)}>
        {i}
      </button>
    )

    if (i === pageCount && pageCount > 6 && curPage < pageCount - 2) {
      elements.push(
        <span key={'left_dots'} className={styles.threeDots}>
          ...
        </span>,
      )
    }

    if (i === 1 || i === pageCount || pageCount < 7) {
      elements.push(btn)
    } else if (i > curPage - 3 && i < curPage + 3) elements.push(btn)

    if (i === 1 && pageCount > 6 && curPage > 3) {
      elements.push(
        <span key={'right_dots'} className={styles.threeDots}>
          ...
        </span>,
      )
    }
  }

  return (
    <div className={classNames(styles.container, className)}>
      <button
        style={{ display: curPage === 1 ? 'none' : 'flex' }}
        className={classNames(styles.arrowBtn, styles.prevBtn)}
        onClick={() => onClick(curPage - 1)}>
        Previous
      </button>
      {elements}
      <button
        style={{ display: curPage === pageCount ? 'none' : 'flex' }}
        className={classNames(styles.arrowBtn, styles.nextBtn)}
        onClick={() => onClick(curPage + 1)}>
        Next
      </button>
    </div>
  )
}

export default Pagination
