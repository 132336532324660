import React, { useEffect, useState } from 'react'
import style from './Exceptions.module.scss'
import spinner from '../../assets/img/spinner.svg'
import Pagination from '../../components/Pagination/Pagination'
import api from '../../api'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)
import { decodeHtml } from '../../utils'
import deleteIcon from 'assets/img/delete.svg'
import fixIcon from 'assets/img/fix.svg'
import TableColTitle from '../../components/TableColTitle/TableColTitle'
import Select from '../../components/Select/Select'
import Button from '../../components/Button/Button'
import VinRemapForm from '../../components/VinRemapForm/VinRemapForm'
import Modal from '../../components/Modal/Modal'
import { Link } from 'react-router-dom'

type OrderBy = { value: keyof Exception; reverse: boolean }

const Exceptions = () => {
  const [date, setDate] = useState<number | undefined>(undefined)
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>({ value: 'id', reverse: false })
  const [page, setPage] = useState(0)
  const [selectedException, setSelectedException] = useState<Exception | undefined>()
  const { data: dates, isFetching: datesIsLoading } = api.useGetExceptionDatesQuery()
  const { data: exceptions, isFetching: exceptionsIsLoading } = api.useGetExceptionsQuery({
    page,
    create_at: date,
    order_by: orderBy ? (orderBy.reverse ? '-' : '') + orderBy.value : undefined,
  })
  const [deleteException, { isLoading: delExcIsLoading }] = api.useDeleteExceptionMutation()
  const [createVinMap, vinMapResp] = api.useCreateVinMapMutation()
  const [createWorkerTask, { isLoading: createTaskIsLoading }] = api.useCreateWorkerTaskMutation()

  const onChangeOrderBy = (value: keyof Exception) => {
    setOrderBy((v) => {
      if (v?.reverse && v.value === value) return undefined
      else {
        const reverse = v?.value === value
        return {
          value,
          reverse,
        }
      }
    })
  }

  const onDelete = (id: number) => {
    deleteException(id)
  }

  const onExceptionClick = (e: Exception) => {
    setSelectedException(e)
    // window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const onCreateVinMap = (body: Omit<VinMap, 'id'>) => {
    const resp = createVinMap(body)
    resp.then((r) => {
      if (!('error' in r)) {
        if (selectedException) deleteException(selectedException.id)
        setSelectedException(undefined)
      }
    })
  }

  useEffect(() => {
    if (dates !== undefined && dates.length > 0) setDate(dates[0])
  }, [dates])

  return (
    <div className={style.page}>
      <div className={style.header}>
        <h1 className={style.title}>Exceptions</h1>
        <Link to={'/vin_maps'}>
          <Button className={style.fixesBtn}>Fixes</Button>
        </Link>
        <Button onClick={() => createWorkerTask()} disabled={createTaskIsLoading} style={{ marginLeft: 'auto' }}>
          Create worker task
        </Button>
      </div>

      {selectedException && (
        <Modal onCloseModal={() => setSelectedException(undefined)}>
          <VinRemapForm
            key={selectedException.id}
            initialValues={{
              vin: selectedException.vin,
              stock: selectedException.stock,
              dealer_name: selectedException.dealer_name,
              make_name: selectedException.make,
              model_name: selectedException.model,
            }}
            onSubmit={onCreateVinMap}
            loading={vinMapResp.isLoading || delExcIsLoading}
            onClose={() => setSelectedException(undefined)}
            error={vinMapResp.isError ? 'Failed to create a fix' : undefined}
          />
        </Modal>
      )}

      <div className={style.filters}>
        <label>Date:</label>
        <Select
          placeholder={'All'}
          disabled={datesIsLoading}
          value={date ? { id: date, label: dayjs(date * 1000).format('lll') } : undefined}
          options={dates?.map((d) => ({ id: d, label: dayjs(d * 1000).format('lll') }))}
          onChange={(v) => setDate(v ? Number(v.id) : undefined)}
          clearable
          btnClassName={style.dateSelectBtn}
        />
      </div>

      <div className={style.tableContainer}>
        {exceptionsIsLoading && (
          <div className={style.tableLoader}>
            <img className={style.tableLoaderSpinner} src={spinner} alt={'Loading...'} />
          </div>
        )}
        <table className={style.table}>
          <colgroup>
            <col span={1} width={'1%'} />
            <col span={1} />
            <col span={1} />
            <col span={1} />
            <col span={1} />
            <col span={1} />
            <col span={1} />
            <col span={1} width={'1%'} />
          </colgroup>
          <thead>
            <tr>
              <th onClick={() => onChangeOrderBy('id')}>
                <TableColTitle name={'id'} label={'Id'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('make')}>
                <TableColTitle name={'make'} label={'Make'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('model')}>
                <TableColTitle name={'model'} label={'Model'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('dealer_name')}>
                <TableColTitle name={'dealer_name'} label={'Dealer'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('stock')}>
                <TableColTitle name={'stock'} label={'Stock'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('vin')}>
                <TableColTitle name={'vin'} label={'Vin'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('create_at')}>
                <TableColTitle name={'create_at'} label={'Create at'} sorted={orderBy} />
              </th>
            </tr>
          </thead>
          <tbody>
            {exceptions?.objects.map((r) => (
              <tr key={r.id}>
                <td>{r.id}</td>
                <td>{r.make ? decodeHtml(r.make) : '-'}</td>
                <td>{r.model ? decodeHtml(r.model) : '-'}</td>
                <td>{r.dealer_name ?? '-'}</td>
                <td>{r.stock ?? '-'}</td>
                <td>{r.vin ?? '-'}</td>
                <td>{r.create_at ? dayjs(r.create_at).format('lll') : '-'}</td>
                <td>
                  <div style={{ display: 'flex' }}>
                    <button
                      className={style.fixBtn}
                      onClick={() => onExceptionClick(r)}
                      title={'Fix'}
                      style={{ marginRight: 10 }}>
                      <img src={fixIcon} alt={'Fix'} />
                    </button>
                    <button
                      className={style.deleteBtn}
                      onClick={() => onDelete(r.id)}
                      title={'Delete'}
                      style={{ marginLeft: 10 }}>
                      <img src={deleteIcon} alt={'Delete'} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {exceptions !== undefined && exceptions.objects.length === 0 && (
          <h2 style={{ width: '100%', textAlign: 'center' }}>No Exceptions</h2>
        )}
      </div>

      {exceptions && (
        <Pagination
          pageCount={exceptions.pagination.count}
          curPage={page + 1}
          onChange={(p) => setPage(p - 1)}
          className={style.pagination}
        />
      )}
    </div>
  )
}

export default Exceptions
