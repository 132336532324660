import React, { useEffect, useState } from 'react'
import styles from './Makes.module.scss'
import api from '../../api'
import Pagination from '../../components/Pagination/Pagination'
import spinner from 'assets/img/spinner.svg'
import TableColTitle from '../../components/TableColTitle/TableColTitle'
import deleteIcon from '../../assets/img/delete.svg'
import MakeForm from '../../components/Make/MakeForm'
import { message } from 'antd'
import Modal from 'components/Modal/Modal'
import Input from 'components/Input/Input'

type OrderBy = { value: keyof Make; reverse: boolean }

const Makes = () => {
  const [showMakeForm, setShowMakeForm] = useState(false)
  const [selectedMake, setSelectedMake] = useState<Make>()
  const [filterName, setFilterName] = useState<string | undefined>()
  const [page, setPage] = useState(0)
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>({ value: 'id', reverse: true })
  const { data: makes, isFetching: makesIsLoading } = api.useGetMakesQuery({
    page,
    name: filterName,
    order_by: orderBy ? (orderBy.reverse ? '-' : '') + orderBy.value : undefined,
  })
  const [createMake, createMakeResponse] = api.useCreateMakeMutation()
  const [patchMake, patchMakeResponse] = api.usePatchMakeMutation()
  const [deleteMake] = api.useDeleteMakeMutation()

  const onAddMakeClick = () => {
    setSelectedMake(undefined)
    setShowMakeForm(true)
  }

  const onMakeClick = (make: Make) => {
    setSelectedMake(make)
    setShowMakeForm(true)
  }

  const onCloseMakeForm = () => {
    setSelectedMake(undefined)
    setShowMakeForm(false)
  }

  const onChangeOrderBy = (value: keyof Make) => {
    setOrderBy((v) => {
      if (v?.reverse && v.value === value) return undefined
      else {
        const reverse = v?.value === value
        return {
          value,
          reverse,
        }
      }
    })
  }

  useEffect(() => {
    // @ts-ignore
    if (patchMakeResponse.error?.data?.error) message.error(patchMakeResponse.error?.data?.error)
    else if (patchMakeResponse.isError) message.error('Error when update a make')
    else if (patchMakeResponse.isSuccess) {
      if (patchMakeResponse.data !== undefined) {
        // @ts-ignore
        if ('error' in patchMakeResponse.data) message.error(patchMakeResponse.data.error)
        else onCloseMakeForm()
      }
    }
  }, [patchMakeResponse])

  useEffect(() => {
    // @ts-ignore
    if (createMakeResponse.error?.data?.error) message.error(createMakeResponse.error?.data?.error)
    else if (createMakeResponse.isError) message.error('Error when creating a make')
    else if (createMakeResponse.isSuccess) {
      if (createMakeResponse.data !== undefined) {
        // @ts-ignore
        if ('error' in createMakeResponse.data) message.error(createMakeResponse.data.error)
        else onCloseMakeForm()
      }
    }
  }, [createMakeResponse])

  return (
    <div>
      <div className={styles.header}>
        <h1 className={styles.title}>Makes</h1>
        <button className={styles.newRuleBtn} onClick={onAddMakeClick} disabled={showMakeForm}>
          Add a New Make
        </button>
      </div>
      {showMakeForm && (
        <Modal onCloseModal={onCloseMakeForm}>
          {selectedMake ? (
            <MakeForm
              key={selectedMake.id}
              onClose={onCloseMakeForm}
              onSubmit={(body) => patchMake({ id: selectedMake!.id, ...body })}
              loading={patchMakeResponse.isLoading}
              initialValues={selectedMake}
              title={'Edit the make'}
            />
          ) : (
            <MakeForm
              onClose={onCloseMakeForm}
              onSubmit={(body) => createMake(body)}
              loading={createMakeResponse.isLoading}
            />
          )}
        </Modal>
      )}
      <div className={styles.filters}>
        <h4>Filters</h4>
        <Input size={'small'} label={'Name'} value={filterName} onChange={setFilterName} />
      </div>
      <div className={styles.tableContainer}>
        {makesIsLoading && (
          <div className={styles.tableLoader}>
            <img className={styles.tableLoaderSpinner} src={spinner} alt={'Loading...'} />
          </div>
        )}
        <table className={styles.table}>
          <thead>
            <tr className={styles.trLine}>
              <th onClick={() => onChangeOrderBy('id')}>
                <TableColTitle name={'id'} label={'Id'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('name')}>
                <TableColTitle name={'name'} label={'Make'} sorted={orderBy} />
              </th>
            </tr>
          </thead>
          <tbody>
            {makes?.objects.map((m) => (
              <tr key={m.id}>
                <td className={styles.ruleId} onClick={() => onMakeClick(m)} style={{ width: 0 }}>
                  {m.id}
                </td>
                <td>{m.name}</td>
                <td style={{ width: 0 }}>
                  <button className={styles.deleteBtn} onClick={() => deleteMake(m.id)}>
                    <img src={deleteIcon} alt={'Delete'} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {makes && (
        <Pagination
          pageCount={makes.pagination.count}
          curPage={page + 1}
          onChange={(p: number) => setPage(p - 1)}
          className={styles.pagination}
        />
      )}
    </div>
  )
}

export default Makes
