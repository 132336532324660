import api from 'api'
import CheckBox from 'components/CheckBox/CheckBox'
import { Button, ChipGroup } from 'motorsport-ui-kit'
import React, { useEffect, useState } from 'react'
import styles from './AddRulePopup.module.scss'
import close from './close.svg'
import Select from '../Select/Select'

type PropsType = {
  onClose: () => void
  rule?: ExclusionRule
  isNew?: boolean
}

const AddRulePopup: React.FC<PropsType> = ({ onClose, rule, isNew = true }) => {
  const [type, setType] = useState<undefined | string>(rule?.type)
  const [carCondition, setCarCondition] = useState<undefined | string>(rule?.new_used)
  const [selectMake, setSelectMake] = useState<undefined | number>(rule?.make)
  const [selectModel, setSelectModel] = useState<undefined | number>(rule?.model)
  const [condition, setCondition] = useState<undefined | string>(rule?.condition)
  const [priceType, setPriceType] = useState<undefined | number>(rule?.price_type)
  const [ruleStatus, setRuleStatus] = useState<boolean>(rule?.rule_status ?? true)
  const [error, setError] = useState<string | undefined>()

  const [createRuleRequest, createRuleResponse] = api.useCreateExclusionRuleMutation()
  const [patchRuleRequest, patchRuleResponse] = api.usePatchExclusionRuleMutation()
  const { data: makes } = api.useGetMakesQuery({ all: true, order_by: 'name' })
  const { data: priceTypes } = api.useGetPriceTypeQuery({})
  const [getModel, modelsData] = api.useLazyGetModelsQuery()
  const models = modelsData.data?.objects

  useEffect(() => {
    if (selectMake) getModel({ make: selectMake, all: true, order_by: 'name' })
  }, [selectMake])

  useEffect(() => {
    // @ts-ignore
    if (createRuleResponse.error?.data?.error) setError(createRuleResponse.error?.data?.error)
    else if (createRuleResponse.isSuccess) {
      if (createRuleResponse.data !== undefined) {
        if ('error' in createRuleResponse.data) setError(createRuleResponse.data.error)
        else onClose()
      }
    }
  }, [createRuleResponse])

  useEffect(() => {
    // @ts-ignore
    if (patchRuleResponse.error?.data?.error) setError(patchRuleResponse.error?.data?.error)
    else if (patchRuleResponse.isSuccess) {
      if (patchRuleResponse.data !== undefined) {
        if ('error' in patchRuleResponse.data) setError(patchRuleResponse.data.error)
        else onClose()
      }
    }
  }, [patchRuleResponse])

  const getMakeSelectValue = () => {
    if (selectMake && makes !== undefined) {
      const make = makes.objects.find((m) => m.id === selectMake)
      if (make) return { id: make.id, label: make.name }
      else return undefined
    } else return undefined
  }

  const getModelSelectValue = () => {
    if (selectModel && models !== undefined) {
      const model = models.find((m) => m.id === selectModel)
      if (model) return { id: model.id, label: model.name }
      else return undefined
    } else return undefined
  }

  const getPriceTypeSelectValue = () => {
    if (priceType && priceTypes !== undefined) {
      const type = priceTypes.find((t) => t.id === priceType)
      if (type) return { id: type.id, label: type.name }
      else return undefined
    } else return undefined
  }

  const onSubmit = () => {
    if (type && carCondition && selectMake && condition && priceType) {
      const ruleBody = {
        new_used: carCondition,
        type: type,
        make: selectMake,
        model: selectModel,
        condition: condition,
        rule_status: ruleStatus,
        price_type: priceType,
      }
      setError(undefined)
      if (isNew) createRuleRequest(ruleBody)
      else patchRuleRequest({ id: rule!.id, body: ruleBody })
    } else {
      setError('Please fill in all fields!')
    }
  }

  return (
    <div className={styles.bg}>
      <div className={styles.closeBtn} onClick={() => onClose()}>
        <img src={close} alt="" />
      </div>
      <div className={styles.popup}>
        <h2 className={styles.title}>{isNew ? 'Create a New Rule' : 'Edit Rule'}</h2>
        <div className={styles.itemContainer}>
          <h4 className={styles.minTitle}>Type </h4>
          <ChipGroup
            chipsData={[
              { id: 1, chipName: 'Include', isActive: type === 'include' },
              { id: 2, chipName: 'Exclude', isActive: type === 'exclude' },
            ]}
            changeFunc={(e) => {
              //@ts-ignore
              setType(e.chipName)
            }}
          />
        </div>
        <div className={styles.itemContainer}>
          <h4 className={styles.minTitle}>Car Condition</h4>
          <ChipGroup
            chipsData={[
              { id: 1, chipName: 'All', isActive: carCondition === 'all' },
              { id: 2, chipName: 'New', isActive: carCondition === 'new' },
              { id: 3, chipName: 'Used', isActive: carCondition === 'used' },
            ]}
            changeFunc={(e) => {
              //@ts-ignore
              setCarCondition(e.chipName)
            }}
          />
        </div>
        <div className={styles.itemContainer}>
          <h4 className={styles.minTitle}>Select Make</h4>
          <Select
            label={'Make'}
            value={getMakeSelectValue()}
            onChange={(v) => setSelectMake(v ? Number(v.id) : undefined)}
            options={makes?.objects.map((m) => ({ id: m.id, label: m.name }))}
            searchable
          />
        </div>
        <div className={styles.itemContainer}>
          <h4 className={styles.minTitle}>Select Model</h4>
          <Select
            label={'Model'}
            value={getModelSelectValue()}
            onChange={(v) => setSelectModel(v ? Number(v.id) : undefined)}
            options={models?.map((m) => ({ id: m.id, label: m.name }))}
            disabled={models === undefined}
            searchable
          />
        </div>
        <div className={styles.itemContainer}>
          <h4 className={styles.minTitle}>Condition</h4>
          <ChipGroup
            chipsData={[
              { id: 1, chipName: '<', isActive: condition === '<' },
              { id: 2, chipName: '>', isActive: condition === '>' },
            ]}
            changeFunc={(e) => {
              //@ts-ignore
              setCondition(e.chipName)
            }}
          />
        </div>

        <div className={styles.itemContainer}>
          <h4 className={styles.minTitle}>Price Type</h4>
          <Select
            label={'Price Type'}
            value={getPriceTypeSelectValue()}
            onChange={(v) => setPriceType(v ? Number(v.id) : undefined)}
            options={priceTypes?.map((p) => ({ id: p.id, label: p.name }))}
            disabled={priceTypes === undefined}
          />
        </div>
        <div className={styles.itemContainer}>
          <h4 className={styles.minTitle}>Rule Status</h4>
          <div className={styles.checkBox}>
            <CheckBox
              id={0}
              onClick={(value) => {
                setRuleStatus(value)
              }}
              selected={ruleStatus}
            />
          </div>
        </div>

        <div style={{ marginTop: 'auto' }}>
          {error && <p className={styles.error}>{error}</p>}
          <Button size="max" className={styles.createBtn} onClick={onSubmit}>
            {isNew ? 'Create' : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddRulePopup
