import React from 'react'
import styles from './Input.module.scss'
import classNames from 'classnames'

type RadioProps = {
  label: string
  value?: number
  onChange: (v: number | undefined) => void
  containerClassname?: string
}

const InputNumber = ({ label, value, onChange, ...props }: RadioProps) => {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      onChange(undefined)
    } else {
      const number = parseInt(e.target.value)
      if (!isNaN(number)) onChange(number)
    }
  }

  return (
    <div className={classNames([styles.container, props.containerClassname])}>
      <input type={'number'} name={label} className={styles.input} value={value} onChange={onInputChange} />
      <label htmlFor={label} className={styles.label}>
        {label}
      </label>
    </div>
  )
}

export default InputNumber
