import React, { useState } from 'react'
import styles from './Login.module.scss'
import { Link, Navigate } from 'react-router-dom'
import api from '../../api'
import { useSelector } from '../../store'

const Register = () => {
  const isAuth = useSelector((state) => state.profile.isAuth)
  const confirmed = useSelector((state) => state.profile.confirmed)
  const [first_name, setFirst_name] = useState('')
  const [last_name, setLast_name] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [registerRequest, registerResponse] = api.useRegisterMutation()
  const requestError =
    registerResponse.isError || (registerResponse.data !== undefined && 'error' in registerResponse.data)

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    registerRequest({ email, password, first_name, last_name })
  }

  if (isAuth && !confirmed) return <Navigate to={'/not_confirmed_user'} />
  else {
    return (
      <div className={styles.page}>
        <form className={styles.form} onSubmit={onSubmit}>
          <h1 className={styles.title}>Create Account</h1>
          <input
            className={styles.input}
            placeholder={'First name'}
            type={'first_name'}
            value={first_name}
            onChange={(e) => setFirst_name(e.target.value)}
          />
          <input
            className={styles.input}
            placeholder={'Last name'}
            type={'last_name'}
            value={last_name}
            onChange={(e) => setLast_name(e.target.value)}
          />
          <input
            className={styles.input}
            placeholder={'Email'}
            type={'email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className={styles.input}
            placeholder={'Password'}
            type={'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div style={{ position: 'relative' }}>
            {requestError && <p className={styles.error}>Register error</p>}
            <button className={styles.submitBtn} type={'submit'} disabled={registerResponse.isLoading}>
              Create account
            </button>
          </div>
          <p>
            Have account? <Link to={'/login'}>Login in</Link>
          </p>
        </form>
      </div>
    )
  }
}

export default Register
