import React from 'react'
import styles from './Header.module.scss'
import { profileActions } from '../../store/profileReducer'
import { useDispatch } from '../../store'
import { Link, useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onLogout = () => {
    dispatch(profileActions.logout())
    navigate('/login')
  }

  return (
    <div className={styles.header}>
      <nav className={styles.nav}>
        <Link to={'/exceptions'}>
          <div className={styles.link}>
            <span>Exceptions</span>
          </div>
        </Link>
        <Link to={'/replacement_rules'}>
          <div className={styles.link}>
            <span>Replacement Rules</span>
          </div>
        </Link>
        <Link to={'/exclusion_rule'}>
          <div className={styles.link}>
            <span>Exclusion Rules</span>
          </div>
        </Link>
        <Link to={'/price_types'}>
          <div className={styles.link}>
            <span>Price Types</span>
          </div>
        </Link>
        <Link to={'/makes_list'}>
          <div className={styles.link}>
            <span>Makes</span>
          </div>
        </Link>
        <Link to={'/models_list'}>
          <div className={styles.link}>
            <span>Models</span>
          </div>
        </Link>
        <Link to={'/logs_explorer'}>
          <div className={styles.link}>
            <span>Logs explorer</span>
          </div>
        </Link>
        <Link to={'/worker_tasks'}>
          <div className={styles.link}>
            <span>Worker tasks</span>
          </div>
        </Link>
        <Link to={'/'} onClick={onLogout}>
          <div className={styles.link}>
            <span>Logout</span>
          </div>
        </Link>
      </nav>
    </div>
  )
}

export default Header
