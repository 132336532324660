import classNames from 'classnames'
import React, { ChangeEvent } from 'react'
import styles from './CheckBox.module.scss'

const CheckBox = ({ id, onClick, selected }: { id: number; onClick: (val: boolean) => void; selected: boolean }) => {
  const inputProp = {
    type: 'checkbox',
    id: 'toggle' + id,
    onChange: (e: ChangeEvent<HTMLInputElement>) => onClick(e.target.checked),
    checked: selected,
  }

  return (
    <label className={classNames(styles.switch, { [styles.autoHistoryCheeckBoxDisabled]: false })}>
      <input {...inputProp} className={styles.autoHistoryCheeckBox} />
      <span className={styles.slider} />
    </label>
  )
}

export default CheckBox
