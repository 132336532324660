import React from 'react'
import style from './Button.module.scss'
import classNames from 'classnames'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({ children, ...props }: Props) => {
  return (
    <button {...props} className={classNames([style.btn, props.className])}>
      {children}
    </button>
  )
}

export default Button
