import React, { useState } from 'react'
import styles from './Login.module.scss'
import { Link, Navigate } from 'react-router-dom'
import api from '../../api'
import { useSelector } from '../../store'

const Login = () => {
  const isAuth = useSelector((state) => state.profile.isAuth)
  const confirmed = useSelector((state) => state.profile.confirmed)
  const [email, setEmail] = useState<string | undefined>()
  const [password, setPassword] = useState<string | undefined>()
  const [loginRequest, loginResponse] = api.useLoginMutation()
  const requestError = loginResponse.isError || (loginResponse.data !== undefined && 'error' in loginResponse.data)
  const btnDisabled = email === undefined || password === undefined || loginResponse.isLoading

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (email !== undefined && password !== undefined) loginRequest({ email, password })
  }

  if (isAuth && !confirmed) return <Navigate to={'/not_confirmed_user'} />
  else {
    return (
      <div className={styles.page}>
        <form className={styles.form} onSubmit={onSubmit}>
          <h1 className={styles.title}>Log In</h1>
          <input
            className={styles.input}
            placeholder={'Email'}
            type={'email'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className={styles.input}
            placeholder={'Password'}
            type={'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div style={{ position: 'relative' }}>
            {requestError && <p className={styles.error}>Login error</p>}
            <button className={styles.submitBtn} type={'submit'} disabled={btnDisabled}>
              Log In
            </button>
          </div>
          <p>
            Don’t have account? <Link to={'/register'}>Sign up</Link>
          </p>
        </form>
      </div>
    )
  }
}

export default Login
