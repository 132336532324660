import style from './TableColTitle.module.scss'
import classNames from 'classnames'
import React from 'react'

const ArrowForSort = ({ className }: { className?: string }) => {
  return (
    <svg width="8" height="4" viewBox="0 0 8 4" fill="#CECED6" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M0 4 L 4 0 L 8 4 Z" />
    </svg>
  )
}

type OrderBy = { value: string; reverse: boolean }

type Props = { label: string } | { name: string; label: string; sorted?: OrderBy }

const TableColTitle = ({ label, ...props }: Props) => {
  return (
    <div className={style.tableColTitleContainer}>
      <span>{label}</span>
      {'sorted' in props && (
        <div className={style.tableColTitleArrows}>
          <ArrowForSort
            className={classNames({
              [style.activeArrow]:
                props.sorted !== undefined && props.sorted.value === props.name && !props.sorted.reverse,
            })}
          />
          <ArrowForSort
            className={classNames({
              [style.activeArrow]:
                props.sorted !== undefined && props.sorted.value === props.name && props.sorted.reverse,
            })}
          />
        </div>
      )}
    </div>
  )
}

export default TableColTitle
