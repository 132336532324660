import api from 'api'
import { Button } from 'motorsport-ui-kit'
import React, { useEffect, useState } from 'react'
import styles from './AddPricePopup.module.scss'
import close from '../AddRulePopup/close.svg'
import Input from 'components/Input/Input'
import InputNumber from '../InputNumber/Input'

type PropsType = {
  onClose: () => void
  priceType?: PriceType
  isNew?: boolean
}

const AddPricePopup: React.FC<PropsType> = ({ onClose, isNew = true, priceType }) => {
  const [name, setName] = useState<string | undefined>(priceType?.name)
  const [price, setPrice] = useState<number | undefined>(priceType?.price)
  const [error, setError] = useState<string | undefined>()

  const [createPriceTypeRequest, createPriceTypeResponse] = api.useCreatePriceTypeMutation()
  const [patchPriceTypeRequest, patchPriceTypeResponse] = api.usePatchPriceTypeMutation()

  useEffect(() => {
    //@ts-ignore
    if (createPriceTypeResponse?.error?.data?.error) setError(createRuleResponse?.error?.data.error)
    else if (createPriceTypeResponse.isSuccess) {
      onClose()
    }
  }, [createPriceTypeResponse])

  useEffect(() => {
    //@ts-ignore
    if (patchPriceTypeResponse?.error?.data?.error) setError(patchPriceTypeResponse?.error?.data.error)
    else if (patchPriceTypeResponse.isSuccess) {
      onClose()
    }
  }, [patchPriceTypeResponse])

  const onSubmit = () => {
    if (name && price) {
      const priceTypeBody = { name: name, price: price }
      setError(undefined)
      if (isNew) createPriceTypeRequest(priceTypeBody)
      else patchPriceTypeRequest({ id: priceType!.id, body: priceTypeBody })
    } else {
      setError('Please fill in all fields!')
    }
  }

  return (
    <div className={styles.bg}>
      <div className={styles.closeBtn} onClick={() => onClose()}>
        <img src={close} alt="" />
      </div>
      <div className={styles.popup}>
        <h2 className={styles.title}>{isNew ? 'Create a Price Type' : 'Edit Price Type'}</h2>
        <div className={styles.itemContainer}>
          <h4 className={styles.minTitle}>Name</h4>
          <Input label={'Please enter name'} value={name} onChange={(e) => setName(e ?? undefined)} />
        </div>
        <div className={styles.itemContainer}>
          <h4 className={styles.minTitle}>Price</h4>
          <InputNumber label={'Please enter price'} value={price} onChange={setPrice} />
        </div>

        <div style={{ marginTop: 'auto' }}>
          {error && <p className={styles.error}>{error}</p>}
          <Button size="max" className={styles.createBtn} onClick={onSubmit}>
            {isNew ? 'Create' : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddPricePopup
