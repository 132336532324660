import React, { useEffect } from 'react'
import styles from './ReplacementRuleForm.module.scss'
import api from 'api'
import Radio from 'components/Radio/Radio'
import Input from 'components/Input/Input'
import { Form } from 'antd'
import Select from 'components/Select/Select'

const matchingRules: MatchingRule[] = ['Equals', 'Start With', 'End With', 'Contains']

type MatchingRuleRadioProps = {
  value?: string
  onChange?: (v?: string) => void
  disabled?: boolean
}

const MatchingRuleRadio = ({ value, onChange, disabled }: MatchingRuleRadioProps) => {
  return (
    <div style={{ width: 'max-content' }}>
      {matchingRules.map((r) => (
        <Radio
          key={r}
          name={r}
          value={value}
          onChange={(v) => onChange?.(v as MatchingRule | undefined)}
          disabled={disabled}
        />
      ))}
    </div>
  )
}

type Props = {
  initialValues?: ReplacementRulesPostBody
  onSubmit?: (rule: ReplacementRulesPostBody) => void
  onClose?: () => void
  loading?: boolean
  error?: string
  title?: string
}

const ReplacementRuleForm = ({ initialValues, ...props }: Props) => {
  const [form] = Form.useForm()
  const { data: makes } = api.useGetMakesQuery({ all: true, order_by: 'name' })
  const [getModels, { data: models, isFetching: modelsIsFetching }] = api.useLazyGetModelsQuery()

  const onSubmit = (e: any) => {
    const form = { ...e, correct_make: e.correct_make.id }
    if (form.correct_model !== undefined) form.correct_model = form.correct_model.id
    props.onSubmit?.(form)
  }

  const onValuesChange = (a: any) => {
    if ('correct_make' in a) {
      form.setFieldsValue({
        correct_model: undefined,
        correct_submodel: undefined,
      })
      const id = Number(a.correct_make.id)
      if (!isNaN(id)) getModels({ make: id, all: true, order_by: 'name' })
    }
    if ('correct_model' in a) {
      form.setFieldsValue({
        correct_submodel: undefined,
      })
    }
  }

  useEffect(() => {
    if (initialValues?.correct_make !== undefined)
      getModels({ make: initialValues.correct_make, all: true, order_by: 'name' })
  }, [])

  return (
    <Form
      form={form}
      name="ReplacementRuleForm"
      className={styles.form}
      initialValues={{
        ...initialValues,
        correct_make: initialValues?.correct_make
          ? { id: initialValues?.correct_make, label: initialValues?.correct_make_name }
          : undefined,
        correct_model: initialValues?.correct_model
          ? { id: initialValues?.correct_model, label: initialValues?.correct_model_name }
          : undefined,
      }}
      onValuesChange={onValuesChange}
      onFinish={onSubmit}
      autoComplete="off"
      requiredMark={false}>
      <h2 className={styles.title}>{props.title ?? 'Create A New Rule'}</h2>
      <div style={{ width: '100%', display: 'flex', marginBottom: 18 }}>
        <Form.Item
          name="wrong_make"
          className={styles.input}
          style={{ marginRight: 10 }}
          rules={[{ required: true, message: 'Please input wrong make!' }]}>
          <Input label={'Make'} />
        </Form.Item>
        <Form.Item name="wrong_model" className={styles.input} style={{ marginLeft: 10 }}>
          <Input label={'Model'} disabled={models === undefined || modelsIsFetching} />
        </Form.Item>
      </div>

      <div style={{ width: '100%', display: 'flex', marginBottom: 18 }}>
        <Form.Item
          name="matching_rule_make"
          className={styles.input}
          style={{ marginRight: 10 }}
          rules={[{ required: true, message: 'Please select matching rule!' }]}>
          <MatchingRuleRadio />
        </Form.Item>
        <Form.Item
          name="matching_rule_model"
          className={styles.input}
          style={{ marginLeft: 10 }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!getFieldValue('wrong_model') || value) return Promise.resolve()
                else return Promise.reject(new Error('Please select matching rule!'))
              },
            }),
          ]}>
          <MatchingRuleRadio disabled={models === undefined || modelsIsFetching} />
        </Form.Item>
      </div>

      <h2 className={styles.title}>Enter The Correct Value</h2>
      <div style={{ width: '100%', display: 'flex', marginBottom: 18 }}>
        <Form.Item
          name={'correct_make'}
          className={styles.input}
          style={{ marginRight: 10 }}
          rules={[{ required: true, message: 'Please select correct make!' }]}>
          <Select
            label={'Make'}
            options={makes?.objects.map((m) => ({ id: m.id, label: m.name }))}
            disabled={makes === undefined}
            searchable
          />
        </Form.Item>
        <Form.Item
          name={'correct_model'}
          className={styles.input}
          style={{ margin: '0 10px' }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!getFieldValue('wrong_model') || value) return Promise.resolve()
                else return Promise.reject(new Error('Please select correct model!'))
              },
            }),
          ]}>
          <Select
            label={'Model'}
            options={models?.objects.map((m) => ({ id: m.id, label: m.name }))}
            disabled={models === undefined || modelsIsFetching}
            searchable
          />
        </Form.Item>
        <Form.Item name={'correct_submodel'} className={styles.input} style={{ marginLeft: 10 }}>
          <Input label={'Submodel'} disabled={models === undefined || modelsIsFetching} />
        </Form.Item>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {props.onClose !== undefined && (
          <button className={styles.closeBtn} type={'button'} onClick={props.onClose} disabled={props.loading}>
            Close
          </button>
        )}
        <div style={{ position: 'relative' }}>
          <button className={styles.createBtn} type={'submit'} disabled={props.loading}>
            Save
          </button>
          {props.error && (
            <p style={{ position: 'absolute', right: 0, color: 'red', whiteSpace: 'nowrap' }}>
              Failed to update a Replacement Rule
            </p>
          )}
        </div>
      </div>
    </Form>
  )
}

export default ReplacementRuleForm
