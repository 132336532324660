import React from 'react'
import styles from './MakeForm.module.scss'
import Input from 'components/Input/Input'
import { Form } from 'antd'

type Props = {
  initialValues?: MakePostBody
  onSubmit?: (rule: MakePostBody) => void
  onClose?: () => void
  loading?: boolean
  error?: string
  title?: string
}

const MakeForm = ({ initialValues, ...props }: Props) => {
  const onSubmit = (e: any) => {
    props.onSubmit?.(e)
  }

  return (
    <Form
      name="MakeForm"
      className={styles.form}
      initialValues={initialValues}
      onFinish={onSubmit}
      autoComplete="off"
      requiredMark={false}>
      <h2 className={styles.title}>{props.title ?? 'Create A New Make'}</h2>
      <div style={{ width: '100%', display: 'flex', marginBottom: 18 }}>
        <Form.Item
          name="name"
          className={styles.input}
          rules={[{ required: true, message: 'Please input make name!' }]}>
          <Input label={'Name'} />
        </Form.Item>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
        {props.onClose !== undefined && (
          <button className={styles.closeBtn} type={'button'} onClick={props.onClose} disabled={props.loading}>
            Close
          </button>
        )}
        <button className={styles.createBtn} type={'submit'} disabled={props.loading}>
          Save
        </button>
        {props.error && (
          <p style={{ position: 'absolute', bottom: -20, right: 0, zIndex: 1, color: 'red' }}>{props.error}</p>
        )}
      </div>
    </Form>
  )
}

export default MakeForm
