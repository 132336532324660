import api from 'api'
import AddPricePopup from 'components/AddPricePopup/AddPricePopup'
import { Button } from 'motorsport-ui-kit'
import React, { useState } from 'react'
import styles from './PriceTypes.module.scss'
import TableColTitle from '../../components/TableColTitle/TableColTitle'

type OrderBy = { value: keyof PriceType; reverse: boolean }

const PriceTypes = () => {
  const [isVisiblePopup, setIsVisiblePopup] = useState(false)
  const [selectedPriceType, setSelectedPriceType] = useState<PriceType | undefined>()
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>({ value: 'id', reverse: false })
  const { data } = api.useGetPriceTypeQuery({
    order_by: orderBy ? (orderBy.reverse ? '-' : '') + orderBy.value : undefined,
  })

  const [delPriceTypeRequest] = api.useDeletePriceTypeMutation()

  const onPriceTypeClick = (priceType: PriceType) => {
    setSelectedPriceType(priceType)
    setIsVisiblePopup(true)
  }

  const onClosePopup = () => {
    setSelectedPriceType(undefined)
    setIsVisiblePopup(false)
  }

  const onChangeOrderBy = (value: keyof PriceType) => {
    setOrderBy((v) => {
      if (v?.reverse && v.value === value) return undefined
      else {
        const reverse = v?.value === value
        return {
          value,
          reverse,
        }
      }
    })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', zIndex: 0 }}>
      {isVisiblePopup && (
        <AddPricePopup onClose={onClosePopup} isNew={selectedPriceType === undefined} priceType={selectedPriceType} />
      )}
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>Price Types</h1>
        <Button onClick={() => setIsVisiblePopup(true)} className={styles.addRule}>
          Add New Price Type
        </Button>
      </div>
      <table cellSpacing="0" cellPadding="0" className={styles.table}>
        <colgroup style={{ width: 'min-content' }} />
        <thead>
          <tr>
            <th onClick={() => onChangeOrderBy('id')}>
              <TableColTitle name={'id'} label={'Id'} sorted={orderBy} />
            </th>
            <th onClick={() => onChangeOrderBy('name')}>
              <TableColTitle name={'name'} label={'Name'} sorted={orderBy} />
            </th>
            <th onClick={() => onChangeOrderBy('price')}>
              <TableColTitle name={'price'} label={'Price'} sorted={orderBy} />
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((e) => (
            <tr className={styles.tableLine} key={e.id}>
              <td onClick={() => onPriceTypeClick(e)} className={styles.priceId}>
                {e.id}
              </td>
              <td>{e.name}</td>
              <td>{e.price}</td>
              <td style={{ width: 50 }}>
                <div onClick={() => delPriceTypeRequest({ id: e.id })} className={styles.delete} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PriceTypes
