import React, { useRef } from 'react'
import styles from './Modal.module.scss'
import { useClickOutside } from '../../hooks/useClickOutside'

type PropsType = {
  onCloseModal?: () => void
}

const Modal: React.FC<PropsType> = ({ children, onCloseModal }) => {
  const ref = useRef<any>()

  useClickOutside(() => onCloseModal?.(), ref)

  return (
    <div className={styles.modal}>
      <div className={styles.content} ref={ref}>
        {children}
      </div>
    </div>
  )
}
export default Modal
