import React from 'react'
import styles from './Input.module.scss'
import classNames from 'classnames'

type RadioProps = {
  label?: string
  value?: string
  onChange?: (v: string | undefined) => void
  containerClassname?: string
  disabled?: boolean
  autoComplete?: boolean
  size?: 'small'
}

const Input = ({ label, value, ...props }: RadioProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === '' ? undefined : e.target.value
    props.onChange?.(value)
  }

  return (
    <div
      className={classNames([styles.container, { [styles.small]: props.size === 'small' }, props.containerClassname])}>
      <input
        autoFocus
        autoComplete={props.autoComplete ? 'on' : 'off'}
        name={label ?? 'input'}
        className={classNames([styles.input, { [styles.withLabel]: label !== undefined }])}
        value={value ? value : ''}
        onChange={onChange}
        disabled={props.disabled}
      />
      {label && (
        <label htmlFor={label} className={styles.label}>
          {label}
        </label>
      )}
    </div>
  )
}

export default Input
