import React from 'react'
import { Outlet } from 'react-router-dom'
import styles from './Layout.module.scss'
import Header from '../Header/Header'

const Layout = () => {
  return (
    <>
      <Header />
      <div className={styles.layout}>
        <Outlet />
      </div>
    </>
  )
}

export default Layout
