import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Layout from './components/Layout/Layout'
import { useSelector } from './store'
import Login from './pages/Login/Login'
import Register from './pages/Login/Register'
import NotConfirmedUser from './pages/Login/NotConfirmedUser'
import ExclusionRules from './pages/ExclusionRules/ExclusionRules'
import ReplacementRules from 'pages/ReplacementRules/ReplacementRules'
import PriceTypes from 'pages/PriceTypes/PriceTypes'
import Exceptions from './pages/Exceptions/Exceptions'
import WorkerTasks from './pages/WorkerTasks/WorkerTasks'
import Makes from './pages/Makes/Makes'
import Models from './pages/Models/Models'
import VinMaps from './pages/VinMaps/VinMaps'
import Logs from './pages/Logs/Logs'

const AuthRouter = () => (
  <Routes>
    <Route path={'/login'} element={<Login />} />
    <Route path={'/login'} element={<Login />} />
    <Route path={'/register'} element={<Register />} />
    <Route path={'/not_confirmed_user'} element={<NotConfirmedUser />} />
    <Route path={'*'} element={<Navigate to="/login" />} />
  </Routes>
)

const AppRouter = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route path={'/'} element={<Navigate to="/exceptions" />} />
      <Route path={'/login'} element={<Navigate to="/" />} />
      <Route path={'/register'} element={<Navigate to="/" />} />
      <Route path={'/not_confirmed_user'} element={<Navigate to="/" />} />
      <Route path={'/exceptions'} element={<Exceptions />} />
      <Route path={'/exclusion_rule'} element={<ExclusionRules />} />
      <Route path={'/vin_maps'} element={<VinMaps />} />
      <Route path={'/replacement_rules'} element={<ReplacementRules />} />
      <Route path={'/price_types'} element={<PriceTypes />} />
      <Route path={'/makes_list'} element={<Makes />} />
      <Route path={'/models_list'} element={<Models />} />
      <Route path={'/logs_explorer'} element={<Logs />} />
      <Route path={'/worker_tasks'} element={<WorkerTasks />} />
      <Route path={'*'} element={<h1>404</h1>} />
    </Route>
  </Routes>
)

const Router = () => {
  const isAuth = useSelector((state) => state.profile.isAuth)
  const isConfirmed = useSelector((state) => state.profile.confirmed)

  if (isAuth && isConfirmed) return <AppRouter />
  else return <AuthRouter />
}

export default Router
