import styles from './Radio.module.scss'
import React from 'react'
import classNames from 'classnames'

type RadioProps = {
  name: string
  value?: string
  onChange: (v?: string) => void
  disabled?: boolean
}

const Radio = ({ name, value, onChange, ...props }: RadioProps) => {
  const isChecked = value === name

  const onClick = () => {
    if (props.disabled) return
    if (isChecked) onChange(undefined)
    else onChange(name)
  }

  return (
    <div className={classNames([styles.container, { [styles.disabled]: props.disabled }])} onClick={onClick}>
      <div className={styles.radio}>{isChecked && <div className={styles.radioMarker} />}</div>
      <span style={{ marginLeft: 12, marginBottom: 2 }}>{name}</span>
    </div>
  )
}

export default Radio
