import React, { useRef, useState } from 'react'
import styles from './VinRemapForm.module.scss'
import api from 'api'
import Input from 'components/Input/Input'
import { Form } from 'antd'
import Select from 'components/Select/Select'
import Button from '../Button/Button'
import { useClickOutside } from 'hooks/useClickOutside'
import plus from 'assets/img/plus.svg'
import MakeForm from '../Make/MakeForm'
import ModelForm from '../Model/ModelForm'

const MakeFormModal = (props: any) => {
  const [error, setError] = useState<string | undefined>()
  const [createMake, createMakeResponse] = api.useCreateMakeMutation()

  const onSubmit = (e: MakePostBody) => {
    createMake(e).then((r) => {
      if (!('error' in r)) {
        props.onSubmit(r.data)
      } else setError('Failed to create a make')
    })
  }

  return <MakeForm onClose={props.onClose} onSubmit={onSubmit} error={error} loading={createMakeResponse.isLoading} />
}

const ModelFormModal = (props: any) => {
  const [error, setError] = useState<string | undefined>()
  const [createModel, createModelResponse] = api.useCreateModelMutation()

  const onSubmit = (e: MakePostBody) => {
    createModel(e).then((r) => {
      if (!('error' in r)) {
        props.onSubmit(r.data)
      } else setError('Failed to create a model')
    })
  }

  return (
    <ModelForm
      initialValues={{ ...props.make }}
      onClose={props.onClose}
      onSubmit={onSubmit}
      error={error}
      loading={createModelResponse.isLoading}
    />
  )
}

type Props = {
  initialValues?: Partial<VinMap & { dealer_name: string; make_name: string; model_name: string }>
  onSubmit?: (rule: Omit<VinMap, 'id'>) => void
  onClose?: () => void
  loading?: boolean
  title?: string
  error?: string
}

const VinRemapForm = ({ initialValues, ...props }: Props) => {
  const [form] = Form.useForm()
  const [showMakeForm, setShowMakeForm] = useState(false)
  const [showModelForm, setShowModelForm] = useState(false)
  const { data: dealers, isFetching: dealersIsLoading } = api.useGetDealersQuery()
  const { data: makes, isFetching: makesIsLoading } = api.useGetMakesQuery({ all: true, order_by: 'name' })
  const [getModels, { data: models, isFetching: modelsIsFetching }] = api.useLazyGetModelsQuery()
  const ref = useRef<any>()

  useClickOutside(() => props?.onClose?.(), ref)

  const onValuesChange = (a: any) => {
    if ('make' in a) {
      form.setFieldsValue({ model: undefined })
      const id = Number(a.make.id)
      if (!isNaN(id)) getModels({ make: id, all: true, order_by: 'name' })
    }
  }

  const onCreateMake = (m: Make) => {
    form.setFieldsValue({ make: { id: m.id, label: m.name } })
    getModels({ make: m.id, all: true, order_by: 'name' })
    setShowMakeForm(false)
  }

  const onCreateModel = (m: Model) => {
    if (m.make !== form.getFieldValue('make').id) {
      form.setFieldsValue({ make: { id: m.make, label: m.make_name } })
    }
    form.setFieldsValue({ model: { id: m.id, label: m.name } })
    setShowModelForm(false)
  }

  const onSubmit = (e: any) => {
    props.onSubmit?.({
      ...e,
      dealer: e.dealer.id,
      make: e.make.id,
      model: e.model.id,
    })
  }

  if (showMakeForm) {
    return <MakeFormModal onClose={() => setShowMakeForm(false)} onSubmit={(m: Make) => onCreateMake(m)} />
  }
  if (showModelForm) {
    const { id: make, label: make_name } = form.getFieldValue('make')
    return (
      <ModelFormModal
        onClose={() => setShowModelForm(false)}
        onSubmit={(m: Model) => onCreateModel(m)}
        make={{ make, make_name }}
      />
    )
  }
  return (
    <Form
      ref={ref}
      form={form}
      name="VinMap"
      className={styles.form}
      initialValues={initialValues}
      onValuesChange={onValuesChange}
      onFinish={onSubmit}
      autoComplete="off"
      requiredMark={false}>
      <h2 className={styles.title}>{props.title ? props.title : 'Create Fix for Exception'}</h2>
      <div style={{ width: '100%', display: 'flex', marginBottom: 18 }}>
        <Form.Item name="stock" className={styles.input} style={{ marginRight: 10 }}>
          <Input label={'Stock'} disabled />
        </Form.Item>
        <Form.Item name="vin" className={styles.input} style={{ marginLeft: 10 }}>
          <Input label={'Vin'} disabled />
        </Form.Item>
      </div>

      <div style={{ width: '100%', display: 'flex' }}>
        <Form.Item
          name={'dealer'}
          className={styles.input}
          style={{ marginRight: 10 }}
          rules={[{ required: true, message: 'Please select dealer!' }]}>
          <Select
            label={'Dealer'}
            placeholder={initialValues?.dealer_name}
            options={dealers?.objects.map((d) => ({ id: d.id, label: d.name }))}
            disabled={makes === undefined || dealersIsLoading}
            searchable
            clearable
          />
        </Form.Item>
        <div style={{ flex: '1 0 0', display: 'flex', margin: '0 10px' }}>
          <Form.Item
            name={'make'}
            className={styles.input}
            style={{ width: '100%' }}
            rules={[{ required: true, message: 'Please select make!' }]}>
            <Select
              key={'make'}
              label={'Make'}
              placeholder={initialValues?.make_name}
              options={makes?.objects.map((m) => ({ id: m.id, label: m.name }))}
              disabled={makes === undefined || makesIsLoading}
              searchable
              clearable
            />
          </Form.Item>
          <Button type={'button'} className={styles.addNewBtn} onClick={() => setShowMakeForm(true)}>
            <img src={plus} alt={'new'} />
          </Button>
        </div>
        <div style={{ flex: '1 0 0', display: 'flex', marginLeft: '10px' }}>
          <Form.Item
            name={'model'}
            className={styles.input}
            style={{ width: '100%' }}
            rules={[{ required: true, message: 'Please select model!' }]}>
            <Select
              key={'model'}
              label={'Model'}
              placeholder={initialValues?.model_name}
              options={models?.objects.map((m) => ({ id: m.id, label: m.name }))}
              disabled={models === undefined || modelsIsFetching}
              searchable
              clearable
            />
          </Form.Item>
          <Button
            type={'button'}
            className={styles.addNewBtn}
            onClick={() => setShowModelForm(true)}
            disabled={models === undefined || modelsIsFetching}>
            <img src={plus} alt={'new'} />
          </Button>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {props.onClose !== undefined && (
          <Button onClick={props.onClose} type={'button'} disabled={props.loading} className={styles.closeBtn}>
            Close
          </Button>
        )}
        <div style={{ position: 'relative' }}>
          <Button type={'submit'} disabled={props.loading} style={{ padding: '10px 60px' }}>
            Save
          </Button>
          {props.error && <p style={{ position: 'absolute', color: 'red' }}>{props.error}</p>}
        </div>
      </div>
    </Form>
  )
}

export default VinRemapForm
