import React from 'react'
import styles from './ModelForm.module.scss'
import api from 'api'
import Input from 'components/Input/Input'
import { Form } from 'antd'
import Select from 'components/Select/Select'

type Props = {
  initialValues?: Partial<Model>
  onSubmit?: (rule: Omit<Model, 'id'>) => void
  onClose?: () => void
  loading?: boolean
  error?: string
  title?: string
}

const ModelForm = ({ initialValues, ...props }: Props) => {
  const { data: makes } = api.useGetMakesQuery({ all: true, order_by: 'name' })

  const onSubmit = (e: any) => {
    props.onSubmit?.({
      ...e,
      make: e.make.id,
    })
  }

  return (
    <Form
      name="ModelForm"
      className={styles.form}
      initialValues={{
        ...initialValues,
        make: initialValues?.make ? { id: initialValues?.make, label: initialValues?.make_name } : undefined,
      }}
      onFinish={onSubmit}
      autoComplete="off"
      requiredMark={false}>
      <h2 className={styles.title}>{props.title ?? 'Create A New Model'}</h2>
      <div style={{ width: '100%', display: 'flex', marginBottom: 18 }}>
        <Form.Item
          name={'make'}
          className={styles.input}
          style={{ marginRight: 10 }}
          rules={[{ required: true, message: 'Please select make!' }]}>
          <Select
            label={'Make'}
            options={makes?.objects.map((m) => ({ id: m.id, label: m.name }))}
            disabled={makes === undefined}
            searchable
          />
        </Form.Item>
        <Form.Item
          name="name"
          className={styles.input}
          style={{ marginLeft: 10 }}
          rules={[{ required: true, message: 'Please input model name!' }]}>
          <Input label={'Name'} />
        </Form.Item>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
        {props.onClose !== undefined && (
          <button className={styles.closeBtn} type={'button'} onClick={props.onClose} disabled={props.loading}>
            Close
          </button>
        )}
        <button className={styles.createBtn} type={'submit'} disabled={props.loading}>
          Save
        </button>
        {props.error && (
          <p style={{ position: 'absolute', bottom: -20, right: 0, zIndex: 1, color: 'red' }}>{props.error}</p>
        )}
      </div>
    </Form>
  )
}

export default ModelForm
