import { applyMiddleware, combineReducers, createStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux'
import { profileReducer } from './profileReducer'
import { persistStore, persistReducer } from 'redux-persist'
import persistStorage from 'redux-persist/lib/storage'
import api from '../api'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage: persistStorage,
  whitelist: ['profile'],
  debug: process.env.NODE_ENV === 'development',
}

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  profile: profileReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer)

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, api.middleware)))

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

export type AppDispatch = typeof store.dispatch

export const useDispatch = () => useReduxDispatch<AppDispatch>()

export const persistor = persistStore(store)
