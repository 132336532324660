import React, { useState } from 'react'
import styles from './WorkerTasks.module.scss'
import api from '../../api'
import Pagination from '../../components/Pagination/Pagination'
import spinner from 'assets/img/spinner.svg'
import TableColTitle from '../../components/TableColTitle/TableColTitle'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Button from '../../components/Button/Button'
dayjs.extend(localizedFormat)

type OrderBy = { value: keyof WorkerTask; reverse: boolean }

const WorkerTasks = () => {
  const [page, setPage] = useState(0)
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>({ value: 'id', reverse: true })
  const { data: tasks, isFetching: tasksIsLoading } = api.useGetWorkerTasksQuery({
    page,
    order_by: orderBy ? (orderBy.reverse ? '-' : '') + orderBy.value : undefined,
  })
  const [createWorkerTask, { isLoading: createTaskIsLoading }] = api.useCreateWorkerTaskMutation()

  const onChangeOrderBy = (value: keyof WorkerTask) => {
    setOrderBy((v) => {
      if (v?.reverse && v.value === value) return undefined
      else {
        const reverse = v?.value === value
        return {
          value,
          reverse,
        }
      }
    })
  }

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <h1 className={styles.title}>Worker tasks</h1>
        <Button onClick={() => createWorkerTask()} disabled={createTaskIsLoading}>
          Create worker task
        </Button>
      </div>
      <div className={styles.tableContainer}>
        {tasksIsLoading && (
          <div className={styles.tableLoader}>
            <img className={styles.tableLoaderSpinner} src={spinner} alt={'Loading...'} />
          </div>
        )}
        <table className={styles.table}>
          <thead>
            <tr>
              <th onClick={() => onChangeOrderBy('id')}>
                <TableColTitle label={'Id'} name={'id'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('status')}>
                <TableColTitle label={'Status'} name={'status'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('time_spend_since_start')}>
                <TableColTitle label={'Time Taken'} name={'time_spend_since_start'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('update_at')}>
                <TableColTitle label={'Update at'} name={'update_at'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('create_at')}>
                <TableColTitle label={'Create at'} name={'create_at'} sorted={orderBy} />
              </th>
            </tr>
          </thead>
          <tbody>
            {tasks?.objects.map((t) => (
              <tr key={t.id}>
                <td>{t.id}</td>
                <td>{t.status ?? '-'}</td>
                <td>{t.time_spend_since_start ?? '-'}</td>
                <td>{t.update_at ? dayjs(t.update_at).format('lll') : '-'}</td>
                <td>{t.create_at ? dayjs(t.create_at).format('lll') : '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {tasks && (
        <Pagination
          pageCount={tasks.pagination.count}
          curPage={page + 1}
          onChange={(p: number) => setPage(p - 1)}
          className={styles.pagination}
        />
      )}
    </div>
  )
}

export default WorkerTasks
