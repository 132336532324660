import React, { useEffect, useState } from 'react'
import styles from './Models.module.scss'
import api from '../../api'
import Pagination from '../../components/Pagination/Pagination'
import spinner from 'assets/img/spinner.svg'
import TableColTitle from '../../components/TableColTitle/TableColTitle'
import deleteIcon from '../../assets/img/delete.svg'
import ModelForm from '../../components/Model/ModelForm'
import { message } from 'antd'
import Modal from 'components/Modal/Modal'
import Input from 'components/Input/Input'

type OrderBy = { value: keyof Model; reverse: boolean }

const Models = () => {
  const [showModelForm, setShowModelForm] = useState(false)
  const [selectedModel, setSelectedModel] = useState<Model>()
  const [filterName, setFilterName] = useState<string | undefined>()
  const [filterMake, setFilterMake] = useState<string | undefined>()
  const [page, setPage] = useState(0)
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>({ value: 'id', reverse: true })
  const { data: models, isFetching: modelsIsLoading } = api.useGetModelsQuery({
    page,
    name: filterName,
    make_name: filterMake,
    order_by: orderBy ? (orderBy.reverse ? '-' : '') + orderBy.value : undefined,
  })
  const [createModel, createModelResponse] = api.useCreateModelMutation()
  const [patchModel, patchModelResponse] = api.usePatchModelMutation()
  const [deleteModel] = api.useDeleteModelMutation()

  const onAddModelClick = () => {
    setSelectedModel(undefined)
    setShowModelForm(true)
  }

  const onModelClick = (model: Model) => {
    setSelectedModel(model)
    setShowModelForm(true)
  }

  const onCloseModelForm = () => {
    setSelectedModel(undefined)
    setShowModelForm(false)
  }

  const onChangeOrderBy = (value: keyof Model) => {
    setOrderBy((v) => {
      if (v?.reverse && v.value === value) return undefined
      else {
        const reverse = v?.value === value
        return {
          value,
          reverse,
        }
      }
    })
  }

  useEffect(() => {
    // @ts-ignore
    if (patchModelResponse.error?.data?.error) message.error(patchModelResponse.error?.data?.error)
    else if (patchModelResponse.isError) message.error('Error when update a model')
    else if (patchModelResponse.isSuccess) {
      if (patchModelResponse.data !== undefined) {
        // @ts-ignore
        if ('error' in patchModelResponse.data) message.error(patchModelResponse.data.error)
        else onCloseModelForm()
      }
    }
  }, [patchModelResponse])

  useEffect(() => {
    // @ts-ignore
    if (createModelResponse.error?.data?.error) message.error(createModelResponse.error?.data?.error)
    else if (createModelResponse.isError) message.error('Error when creating a model')
    else if (createModelResponse.isSuccess) {
      if (createModelResponse.data !== undefined) {
        // @ts-ignore
        if ('error' in createModelResponse.data) message.error(createModelResponse.data.error)
        else onCloseModelForm()
      }
    }
  }, [createModelResponse])

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <h1 className={styles.title}>Models</h1>
        <button className={styles.newRuleBtn} onClick={onAddModelClick} disabled={showModelForm}>
          Add a New Model
        </button>
      </div>
      {showModelForm && (
        <Modal onCloseModal={onCloseModelForm}>
          {selectedModel ? (
            <ModelForm
              key={selectedModel.id}
              onClose={onCloseModelForm}
              onSubmit={(body) => patchModel({ id: selectedModel!.id, ...body })}
              loading={patchModelResponse.isLoading}
              initialValues={selectedModel}
              title={'Edit the model'}
            />
          ) : (
            <ModelForm
              onClose={onCloseModelForm}
              onSubmit={(body) => createModel(body)}
              loading={createModelResponse.isLoading}
            />
          )}
        </Modal>
      )}
      <div className={styles.filters}>
        <h4>Filters</h4>
        <Input size={'small'} label={'Make'} value={filterMake} onChange={setFilterMake} />
        <Input size={'small'} label={'Name'} value={filterName} onChange={setFilterName} />
      </div>
      <div className={styles.tableContainer}>
        {modelsIsLoading && (
          <div className={styles.tableLoader}>
            <img className={styles.tableLoaderSpinner} src={spinner} alt={'Loading...'} />
          </div>
        )}
        <table className={styles.table}>
          <thead>
            <tr className={styles.trLine}>
              <th onClick={() => onChangeOrderBy('id')}>
                <TableColTitle name={'id'} label={'Id'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('make')}>
                <TableColTitle name={'make'} label={'Make'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('name')}>
                <TableColTitle name={'name'} label={'Model'} sorted={orderBy} />
              </th>
            </tr>
          </thead>
          <tbody>
            {models?.objects.map((m) => (
              <tr key={m.id}>
                <td className={styles.ruleId} onClick={() => onModelClick(m)} style={{ width: 0 }}>
                  {m.id}
                </td>
                <td>{m.make_name}</td>
                <td>{m.name}</td>
                <td style={{ width: 0 }}>
                  <button className={styles.deleteBtn} onClick={() => deleteModel(m.id)}>
                    <img src={deleteIcon} alt={'Delete'} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {models && (
        <Pagination
          pageCount={models.pagination.count}
          curPage={page + 1}
          onChange={(p: number) => setPage(p - 1)}
          className={styles.pagination}
        />
      )}
    </div>
  )
}

export default Models
