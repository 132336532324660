import React, { useEffect, useState } from 'react'
import styles from './ReplacementRules.module.scss'
import api from '../../api'
import Pagination from '../../components/Pagination/Pagination'
import spinner from 'assets/img/spinner.svg'
import TableColTitle from '../../components/TableColTitle/TableColTitle'
import ReplacementRuleForm from '../../components/ReplacementRule/ReplacementRuleForm'
import deleteIcon from '../../assets/img/delete.svg'
import Modal from 'components/Modal/Modal'
import Select from '../../components/Select/Select'

type OrderBy = { value: keyof ReplacementRule; reverse: boolean }

const ReplacementRules = () => {
  const [showRuleForm, setShowRuleForm] = useState(false)
  const [selectedRule, setSelectedRule] = useState<ReplacementRule>()
  const [orderBy, setOrderBy] = useState<OrderBy | undefined>({ value: 'id', reverse: true })
  const [page, setPage] = useState<number>(0)
  const [make, setMake] = useState<number | undefined>(undefined)
  const [model, setModel] = useState<number | undefined>(undefined)
  const { data: makes } = api.useGetMakesQuery({ all: true, order_by: 'name' })
  const [getModel, modelsData] = api.useLazyGetModelsQuery()
  const { data: rules, isFetching: rulesIsLoading } = api.useGetReplacementRulesQuery({
    page,
    correct_make: make?.toString(),
    correct_model: model?.toString(),
    order_by: orderBy ? (orderBy.reverse ? '-' : '') + orderBy.value : undefined,
  })
  const [createRule, createRuleResp] = api.useCreateReplacementRulesMutation()
  const [patchRule, patchRuleResp] = api.usePatchReplacementRulesMutation()
  const [deleteRule] = api.useDeleteReplacementRuleMutation()

  const getMakeSelectValue = () => {
    if (make && makes !== undefined) {
      const m = makes.objects.find((m) => m.id === make)
      if (m) return { id: m.id, label: m.name }
      else return undefined
    } else return undefined
  }

  const getModelSelectValue = () => {
    if (model && modelsData.data?.objects !== undefined) {
      const m = modelsData.data.objects.find((m) => m.id === model)
      if (m) return { id: m.id, label: m.name }
      else return undefined
    } else return undefined
  }

  const onAddRuleClick = () => {
    setSelectedRule(undefined)
    setShowRuleForm(true)
  }

  const onRuleClick = (rule: ReplacementRule) => {
    setSelectedRule(rule)
    setShowRuleForm(true)
  }

  const onCloseRuleForm = () => {
    setSelectedRule(undefined)
    setShowRuleForm(false)
  }

  const onChangeOrderBy = (value: keyof ReplacementRule) => {
    setOrderBy((v) => {
      if (v?.reverse && v.value === value) return undefined
      else {
        const reverse = v?.value === value
        return {
          value,
          reverse,
        }
      }
    })
  }

  const onCreateRule = (body: ReplacementRulesPostBody) => {
    const resp = createRule(body)
    resp.then((r) => {
      if (!('error' in r)) {
        onCloseRuleForm()
      }
    })
  }

  const onPatchRule = (body: ReplacementRulesPostBody) => {
    const resp = patchRule({ id: selectedRule!.id, ...body })
    resp.then((r) => {
      if (!('error' in r)) {
        onCloseRuleForm()
      }
    })
  }

  useEffect(() => {
    if (make) getModel({ make: make, all: true, order_by: 'name' })
    else {
      setModel(undefined)
    }
  }, [make])

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <h1 className={styles.title}>Replacement rules</h1>
        <button className={styles.newRuleBtn} onClick={onAddRuleClick} disabled={showRuleForm}>
          Add a New Rule
        </button>
      </div>
      {showRuleForm && (
        <Modal onCloseModal={() => onCloseRuleForm()}>
          {selectedRule ? (
            <ReplacementRuleForm
              onClose={onCloseRuleForm}
              onSubmit={onPatchRule}
              loading={patchRuleResp.isLoading}
              initialValues={selectedRule}
              error={patchRuleResp.isError ? 'Failed to update a Replacement Rule' : undefined}
              title={'Edit the rule'}
            />
          ) : (
            <ReplacementRuleForm
              onClose={onCloseRuleForm}
              onSubmit={onCreateRule}
              loading={createRuleResp.isLoading}
              error={createRuleResp.isError ? 'Failed to create a Replacement Rule' : undefined}
            />
          )}
        </Modal>
      )}
      <div className={styles.filters}>
        <h4>Filters</h4>
        <Select
          options={makes?.objects.map((m) => ({ id: m.id, label: m.name }))}
          disabled={makes === undefined}
          label={'Make'}
          searchable
          clearable
          size={'small'}
          value={getMakeSelectValue()}
          onChange={(v) => setMake(v ? Number(v.id) : undefined)}
          btnClassName={styles.makeFilter}
        />
        <Select
          options={make !== undefined ? modelsData.data?.objects.map((m) => ({ id: m.id, label: m.name })) : undefined}
          disabled={make === undefined || modelsData.data === undefined}
          label={'Model'}
          searchable
          clearable
          size={'small'}
          value={getModelSelectValue()}
          onChange={(v) => setModel(v ? Number(v.id) : undefined)}
          btnClassName={styles.makeFilter}
        />
      </div>
      <div className={styles.tableContainer}>
        {rulesIsLoading && (
          <div className={styles.tableLoader}>
            <img className={styles.tableLoaderSpinner} src={spinner} alt={'Loading...'} />
          </div>
        )}
        <table className={styles.table}>
          <thead>
            <tr className={styles.trLine}>
              <th onClick={() => onChangeOrderBy('id')}>
                <TableColTitle name={'id'} label={'Id'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('wrong_make')}>
                <TableColTitle name={'wrong_make'} label={'Wrong make'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('matching_rule_make')}>
                <TableColTitle name={'matching_rule_make'} label={'Make rule'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('correct_make')}>
                <TableColTitle name={'correct_make'} label={'Correct make'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('wrong_model')}>
                <TableColTitle name={'wrong_model'} label={'Wrong model'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('matching_rule_model')}>
                <TableColTitle name={'matching_rule_model'} label={'Model rule'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('correct_model')}>
                <TableColTitle name={'correct_model'} label={'Correct model'} sorted={orderBy} />
              </th>
              <th onClick={() => onChangeOrderBy('correct_submodel')}>
                <TableColTitle name={'correct_submodel'} label={'Correct submodel'} sorted={orderBy} />
              </th>
            </tr>
          </thead>
          <tbody>
            {rules?.objects.map((r) => (
              <tr key={r.id}>
                <td className={styles.ruleId} onClick={() => onRuleClick(r)}>
                  {r.id}
                </td>
                <td>{r.wrong_make ?? '-'}</td>
                <td>{r.matching_rule_make ?? '-'}</td>
                <td>{r.correct_make_name ?? '-'}</td>
                <td>{r.wrong_model ?? '-'}</td>
                <td>{r.matching_rule_model ?? '-'}</td>
                <td>{r.correct_model_name ?? '-'}</td>
                <td>{r.correct_submodel ?? '-'}</td>
                <td>
                  <button className={styles.deleteBtn} onClick={() => deleteRule(r.id)}>
                    <img src={deleteIcon} alt={'Delete'} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {rules && (
        <Pagination
          pageCount={rules.pagination.count}
          curPage={page + 1}
          onChange={(p: number) => setPage(p - 1)}
          className={styles.pagination}
        />
      )}
    </div>
  )
}

export default ReplacementRules
