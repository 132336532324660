import React, { useRef, useState } from 'react'
import style from './Select.module.scss'
import classNames from 'classnames'
import { useClickOutside } from '../../hooks/useClickOutside'
import Input from '../Input/Input'
import clearIcon from 'assets/img/close.svg'

type Value<T> = { id: T; label: string }

type Props<T> = {
  value?: Value<T>
  defaultValue?: Value<T>
  options?: Value<T>[]
  onChange?: (v?: Value<T>) => void
  disabled?: boolean
  btnClassName?: string
  placeholder?: string
  label?: string
  searchable?: boolean
  clearable?: boolean
  size?: 'small'
}

const Select = <T extends string | number>({ value, ...props }: Props<T>) => {
  const [defaultValue, setDefaultValue] = useState(props.defaultValue)
  const [showOptions, setShowOptions] = useState(false)
  const [search, setSearch] = useState<string | undefined>()
  const ref = useRef<any>()
  const hasOptions = props.options !== undefined && props.options.length > 0

  const getOptions = () => {
    const options = props.options
      ?.filter((o) => search === undefined || o.label.toLowerCase().includes(search.toLowerCase()))
      .map((o) => (
        <button key={o.id} className={style.option} onClick={() => onChange(o)}>
          {o.label}
        </button>
      ))

    if (options && options.length > 0) return options
    else
      return (
        <button key={'no_data'} className={style.no_data} disabled>
          No data
        </button>
      )
  }

  const onChange = (v: Value<T>) => {
    setDefaultValue(undefined)
    props.onChange?.(v)
    closeDropDawn()
  }

  const closeDropDawn = () => {
    setShowOptions(false)
    setSearch(undefined)
  }

  const toggleDropDawn = () => {
    setShowOptions((s) => !s)
    setSearch(undefined)
  }

  useClickOutside(closeDropDawn, ref)

  return (
    <div className={classNames([style.container, { [style.small]: props.size === 'small' }])} ref={ref}>
      <button
        type={'button'}
        disabled={props.disabled}
        className={classNames([
          style.btn,
          { [style.withLabel]: props.label !== undefined, [style.open]: showOptions },
          props.btnClassName,
        ])}
        onClick={toggleDropDawn}>
        {props.label && <span className={style.label}>{props.label}</span>}
        {value ? (
          <span className={style.value}>{value?.label}</span>
        ) : defaultValue ? (
          <span className={style.value}>{defaultValue?.label}</span>
        ) : props.placeholder ? (
          <span className={style.placeholder}>{props.placeholder}</span>
        ) : (
          <span className={style.value}>&nbsp;</span>
        )}
        {props.clearable && value && (
          <button onClick={() => props.onChange?.(undefined)} className={style.clearBtn}>
            <img src={clearIcon} alt={'Clear'} />
          </button>
        )}
      </button>
      {showOptions && (
        <div className={style.dropDawnContainer}>
          {props.searchable && hasOptions && (
            <Input containerClassname={style.searchInput} value={search} onChange={setSearch} size={props.size} />
          )}
          <div className={style.options}>{getOptions()}</div>
        </div>
      )}
    </div>
  )
}

export default Select
