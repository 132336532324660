import React from 'react'
import styles from './Login.module.scss'
import { useDispatch, useSelector } from '../../store'
import { profileActions } from '../../store/profileReducer'
import { Navigate, useNavigate } from 'react-router-dom'

const NotConfirmedUser = () => {
  const isAuth = useSelector((state) => state.profile.isAuth)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onLogout = () => {
    dispatch(profileActions.logout())
    navigate('/login')
  }

  if (!isAuth) return <Navigate to={'/login'} />
  else {
    return (
      <div className={styles.page}>
        <h1 style={{ marginBottom: 32 }}>Your account is not verified</h1>
        <button onClick={onLogout} className={styles.logoutBtn}>
          Logout
        </button>
      </div>
    )
  }
}

export default NotConfirmedUser
