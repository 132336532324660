import React from 'react'
import Router from './Router'
import api from './api'
import 'motorsport-ui-kit/dist/motorsport-ui-kit.cjs.development.css'

function App() {
  api.useGetUserQuery()
  return <Router />
}

export default App
